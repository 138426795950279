import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [0,3,2,6];

export const dictionary = {
		"/": [~7],
		"/(app)/account": [8,[3]],
		"/(app)/account/billing": [~9,[3],[,4]],
		"/(app)/account/members": [~10,[3]],
		"/(app)/account/usage": [~11,[3],[,5]],
		"/(auth)/authenticate": [~23],
		"/playground": [~24],
		"/(app)/projects": [~12,[2]],
		"/(app)/projects/create": [22,[2]],
		"/(app)/projects/[projectId]": [~13,[6]],
		"/(app)/projects/[projectId]/collections": [~14,[6]],
		"/(app)/projects/[projectId]/collections/[name]": [~15,[6]],
		"/(app)/projects/[projectId]/playground": [~16,[6]],
		"/(app)/projects/[projectId]/sessions": [~17,[6]],
		"/(app)/projects/[projectId]/sessions/[id]": [~18,[6]],
		"/(app)/projects/[projectId]/settings": [~19,[6]],
		"/(app)/projects/[projectId]/users": [~20,[6]],
		"/(app)/projects/[projectId]/users/[id]": [~21,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';