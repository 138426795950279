import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import { env as publicEnv } from '$env/dynamic/public';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: publicEnv.PUBLIC_SENTRY_DSN,
	tracesSampleRate: publicEnv.PUBLIC_SENTRY_TRACES_SAMPLE_RATE as unknown as number,
	environment: publicEnv.PUBLIC_SENTRY_ENVIRONMENT,
	ignoreErrors: ['Non-Error promise rejection captured'],

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate:
		publicEnv.PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE as unknown as number,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [new Replay()]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
